import React, { useState } from "react";
import "./ComoFunciona.css"

/* img e icons*/
import Passo1 from "../../img/como-funciona/passo1.png"
import Passo2 from "../../img/como-funciona/passo2.png"
import Passo3 from "../../img/como-funciona/passo3.png"
import numero1 from "../../img/Icons/numero1.png"
import numero2 from "../../img/Icons/numero2.png"
import numero3 from "../../img/Icons/numero3.png"
// import arrow from "../../img/Icons/arrow.png"
import arrow from "../../img/Icons/arrow-transparente.png"
import disableArrow from "../../img/Icons/disable-back-arrow.png"
import backArrow from "../../img/Icons/back-arrow-transparente.png"
import disableArrow2 from "../../img/Icons/disable-arrow.png"


function ComoFunciona (){

    const proximaDica = () => {
        if (imagensDicasEtapa <= 1) {
          setImagensDicasEtapa(imagensDicasEtapa + 1);
          setSpanDicasEtapa(spanDicasEtapa + 1);
        } else {
          setImagensDicasEtapa(0);
          setSpanDicasEtapa(0);
        }
      };
    
      const anteriorDica = () => {
        if (imagensDicasEtapa >= 1) {
          setImagensDicasEtapa(imagensDicasEtapa - 1);
          setSpanDicasEtapa(spanDicasEtapa - 1);
        } else {
          setImagensDicasEtapa(2);
          setSpanDicasEtapa(2);
        }
      };

    
      const [imagensDicasEtapa, setImagensDicasEtapa] = useState(0);
      const [spanDicasEtapa, setSpanDicasEtapa] = useState(0);
    
      const imagensDicas = [
        <img
          className="como-funciona-img"
          src={Passo1}
          alt="Ilustração fazendo pedido pelo celular"
        />,
        <img
          className="como-funciona-img"
          src={Passo2}
          alt="Ilustração recebendo pedidos"
        />,
        <img
          className="como-funciona-img"
          src={Passo3}
          alt="Ilustração embalagem na mão"
        />,
      ];
    
      const spanDicas = [



        /*   Passo 1 */
        <div className="box-passos-como-funciona">
          <div className="passos-title-box">
            <img className="numero-icon" src={numero1} alt="ícone de número um"/>
            <span className="passos-title"> O cliente faz o pedido</span>
          </div>

          <div className="passos-text3">
            O cliente escolhe seu restaurante no app, seleciona o prato, escolhe como quer pagar, 
            onde é pra entregar e o pedido é enviado pro seu restaurante.
          </div>

          <div className="box-passos-como-funciona">
            <img className='passos-arrow-icon' src={disableArrow} alt="Flecha voltar desativada"/>
            <img onClick={() => proximaDica()} className='passos-arrow-icon cursor-como-funciona' src={arrow} alt="Flecha continuar"/>
          </div>
        </div>,

        /*   Passo 2 */
        <div className="box-passos-como-funciona">
            <div className="passos-title-box">
              <img className="numero-icon" src={numero2} alt="ícone de número dois"/>
              <span className="passos-title"> Você recebe o pedido</span>
            </div>

            <div className="passos-text3">
              O pedido chega no seu celular ou computador com todas as informações necessárias, 
              você envia pra cozinha e inicia a montagem e preparo do prato.
            </div>

            <div className="box-passos-como-funciona">
              <img onClick={() => anteriorDica()} className='passos-arrow-icon cursor-como-funciona' src={backArrow} alt="Flecha voltar desativada"/>
              <img onClick={() => proximaDica()} className='passos-arrow-icon cursor-como-funciona' src={arrow} alt="Flecha continuar"/>
            </div>
        </div>,

        /*   Passo 3 */
        <div className="box-passos-como-funciona">
            <div className="passos-title-box">
              <img className="numero-icon" src={numero3} alt="ícone de número três"/>
              <span className="passos-title"> Pedido pronto</span>
            </div>
            <div className="passos-text3">
              Depois de pronto o app avisa o cliente, de forma automática, 
              de que o pedido está pronto e saindo pra entrega.
            </div>
              
            <div className="box-passos-como-funciona">
              <img onClick={() => anteriorDica()} className='passos-arrow-icon cursor-como-funciona' src={backArrow} alt="Flecha voltar desativada"/>
              <img className='passos-arrow-icon' src={disableArrow2} alt="Flecha continuar"/>
            </div>
        </div>,
      ];

    return(
        <div>

            <div className="gray-line-como-funciona"></div>

            <div className='main-container-como-funciona'>

              <div className="como-funciona-container2">

                <div id="como-funciona" className='como-funciona-title'>
                    Como funciona <br></br>a plataforma?
                </div>

                <div className='como-funciona-title2'>
                  Veja como é fácil
                </div>

              </div>

                <div className='passos-container'>

                  {spanDicas[spanDicasEtapa]}

                </div>

                <div className='como-funciona-img-container'>
                  {imagensDicas[imagensDicasEtapa]}
                </div>

            </div>


            <div className="espaco-mobile-gray-line"></div>
            
        </div>
    )

}

export default ComoFunciona;