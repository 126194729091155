import React, { useState } from "react";
import "./EtapasCadastro.css"

import emojiEscrevendo from "../../img/etapasCadastro/emoji-escrevendo.png"
import emojiPositivo from "../../img/etapasCadastro/emoji-positivo.png"
import emojiPrato from "../../img/etapasCadastro/emoji-prato.png"
import emojiEstrela from "../../img/etapasCadastro/emoji-estrela.png"
import arrow from "../../img/Icons/arrow-transparente.png"
import disableArrow from "../../img/Icons/disable-back-arrow.png"
import disableArrow2 from "../../img/Icons/disable-arrow.png"
import backArrow from "../../img/Icons/back-arrow-transparente.png"
import etapa1 from "../../img/etapasCadastro/etapa1.png"
import etapa2 from "../../img/etapasCadastro/etapa2.png"
import etapa3 from "../../img/etapasCadastro/etapa3.png"
import etapa4 from "../../img/etapasCadastro/etapa4.png"



function EtapasCadastro() {

    const proximaDica = () => {
        if (spanDicasEtapa <= 2) {
          setSpanDicasEtapa(spanDicasEtapa + 1);
        } else {
            setSpanDicasEtapa(0);
          }
    };
    
    const anteriorDica = () => {
        if (spanDicasEtapa >= 1) {
          setSpanDicasEtapa(spanDicasEtapa - 1);
        } else {
          setSpanDicasEtapa(3);
        }
    };

    const [spanDicasEtapa, setSpanDicasEtapa] = useState(0);
    
    const spanDicas = [

        /*  Etapa 1 */

        <div>
            <img className="img-etapa" src={etapa1} alt="Ilustração Homem no Computador" />

            <div className="etapa-container"> 

                <div className="border-etapa1">

                    <div className="etapa-text-container"> 

                        <img className="emoji-cadastro emoji-border" src={emojiEscrevendo} alt="Emoji Mão Escrevendo"/>
                        <span className="etapa-text">1. Preenchimento do formulário</span>
                        <span className="etapa-describe etapa-describe-mobile1">
                            Você preenche o formulário com as informações do seu negócio
                        </span>

                    </div>
                </div>

            </div>

            <div className="arrows-div">
                    <img className='etapa-arrow-icon' src={disableArrow} alt="Flecha voltar desativada"/>
                    <img onClick={() => proximaDica()} className='etapa-arrow-icon cursor-cadastro' src={arrow} alt="Flecha continuar"/>
                </div>
        </div>,

        /*   Etapa 2 */

        <div>
            <img className="img-etapa" src={etapa2} alt="Ilustração Homem Feliz no Computador" />

            <div className="etapa-container">

                <div className="border-etapa1">

                    <div className="etapa-text-container">
                        <img className="emoji-cadastro emoji-border" src={emojiPositivo} alt="Emoji Sinal de Positivo Mão"/>
                        <span className="etapa-text">2. Análise do cadastro </span>
                        <span className="etapa-describe etapa-describe-mobile2"> 
                            Nosso time irá analisar as informações enviadas e estando tudo ok, 
                            você receberá o contrato via e-mail pra assinar
                        </span>
                    </div>

                </div>

            </div>

            <div className="arrows-div">
                <img onClick={() => anteriorDica()} className='etapa-arrow-icon cursor-cadastro' src={backArrow} alt="Flecha voltar desativada"/>
                <img onClick={() => proximaDica()} className='etapa-arrow-icon cursor-cadastro' src={arrow} alt="Flecha continuar"/>
            </div>
        </div>,

        /*   Etapa 3 */ 

        <div>
            <img className="img-etapa" src={etapa3} alt="Ilustração Homem se cadastrando no Computador" />

            <div className="etapa-container">

                <div className="border-etapa1">

                    <div className="etapa-text-container">
                        <img className="emoji-cadastro emoji-border" src={emojiPrato} alt="Emoji Prato e Talheres"/>
                        <span className="etapa-text-3">3. Montagem e ativação do restaurante</span>
                        <span className= "etapa-describe  etapa-describe-mobile3">
                            Seu restaurante será montado com todas as informações fornecidas e, após pronto, 
                            você receberá as instruções de como vender no app
                        </span>
                    </div>
                </div>

            </div>

            <div className="arrows-div">
                <img onClick={() => anteriorDica()} className='etapa-arrow-icon cursor-cadastro' src={backArrow} alt="Flecha voltar desativada"/>
                <img onClick={() => proximaDica()} className='etapa-arrow-icon cursor-cadastro' src={arrow} alt="Flecha continuar"/>
            </div>
        </div>,

        /*   Etapa 4 */

        <div>
            <img className="img-etapa" src={etapa4} alt="Ilustração Homem fazendo Festa" />

            <div className="etapa-container">

                <div className="border-etapa1">
                    <div className="etapa-text-container">
                        <img className="emoji-cadastro emoji-border" src={emojiEstrela} alt="Emoji Estrela"/>
                        <span className="etapa-text">4. Loja ativa! Agora é só vender!</span>
                        <span className="etapa-describe etapa-describe-mobile4">
                            Seu restaurante estará disponível para milhares de novos clientes dentro do app, 
                            boas vendas!
                        </span>
                    </div>
                </div>

            </div>

            <div className="arrows-div">
                <img onClick={() => anteriorDica()} className='etapa-arrow-icon cursor-cadastro' src={backArrow} alt="Flecha voltar desativada"/>
                <img className='etapa-arrow-icon' src={disableArrow2} alt="Flecha continuar"/>
            </div>
        </div>,
    ];
      
    return(
        <div>
            <div className='main-container-etapas'>

                <div className="etapas-cadastro-title">
                    Etapas do cadastro
                </div>

                <div className="etapas-cadastro-subtitle">
                    Em poucos passos você faz o seu cadastro:
                </div>

                {spanDicas[spanDicasEtapa]}

                {/* <div className="arrows-container">
                    <div className="arrows-div">
                        <img onClick={() => anteriorDica()} className='etapa-arrow-icon' src={disableArrow} alt="Flecha voltar desativada"/>
                        <img onClick={() => proximaDica()} className='etapa-arrow-icon' src={arrow} alt="Flecha continuar"/>
                    </div>
                </div> */}

            </div>


{/* TENTAR AQUI */}
            {/* <div className="arrows-div">
                    <img onClick={() => anteriorDica()} className='etapa-arrow-icon' src={disableArrow} alt="Flecha voltar desativada"/>
                    <img onClick={() => proximaDica()} className='etapa-arrow-icon' src={arrow} alt="Flecha continuar"/>
            </div> */}
        </div>
    )
}

export default EtapasCadastro;