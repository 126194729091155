import React, { useState, useEffect } from 'react';
import './Header.css'
import {AiFillCloseCircle,} from "react-icons/ai";
import {spCidadesArray, msCidadesArray} from "../../apiCidades";


/*Import de imagens e icons */
import logo from '../../img/logo/vermelho@2x.png'
import locationIcon from '../../img/Icons/location-icon.png'
import arrowIcon from '../../img/Icons/down-arrow.png'



function Header({getCity, cidade, siglaEstado}){


    // const [cidade, setCidade] = useState("");
    // const [siglaEstado, setSiglaEstado] = useState("");
    // const [titulo, setTitulo]= useState("Aumente as vendas \A do seu delivery");
    // const [descricao, setDescricao]= useState("Venda mais através da plataforma \A de delivery com melhor custo\A benefício do Brasil");

    

    //   async function getCidades(){

    //     const req = await fetch("https://www.appedidos.com.br/rests/rest_cidades",{
    //         method:"GET",
    //         body: JSON.stringify(),
    //         headers: {
    //             "Content-Type":"Application/Json"
    //         }
    //     })

    //     const res = await req.json()
    //     console.log(res)
    //   }


    /*MODAL LOCALIZAÇÃO*/

    const openModal = () => {
        const tela = document.getElementById("modal-selecione-sua-cidade");

        tela.classList.remove("escondidoDireita");
    };

    const closeModal = () => {
        const tela = document.getElementById("modal-selecione-sua-cidade");

        tela.classList.add("escondidoDireita");
    };

    // const saveCity = () => {
         
    // }

    /*SELECT CIDADE */

    // const getCity = (item, e) => {

    //     const cidadeEscolhida = document.getElementsByClassName("citySelected");

    //     if (cidadeEscolhida.length < 1) {
    //           e.target.classList.add("citySelected");
        
    //         if (cidadeEscolhida.length === 1){
    //             setCidade(e.target.value)
    //             cityData(item.Cidade.id)
    //             setSiglaEstado(item.Estado.sigla)
    //         }

    //     } else if (e.target.classList.contains("citySelected")){
    //         e.target.classList.remove("citySelected");
    //       setCidade("")
    //       setSiglaEstado("") 
    //     }

    // };

    // async function cityData(cidade_id){

    //     const req = await fetch('http://localhost:3000/api', {
    //             method:'POST',
    //             body: JSON.stringify({
    //                 cidade_id: cidade_id,
    //             }),
    //             headers:{
    //                 'Content-Type':'application/json'
    //             }
    //         })
    //         const res = await req.json()
    //         if(res.data == true){
    //             console.log(res.city)
    //         } else {
    //             console.log(res.data)
    //         }
    // }

    // postLocation()

    // async function postLocation(){

    //     const req = await fetch('http://localhost:3000/api',{
    //             method:'POST',
    //             body: JSON.stringify({
    //                 cidade: cidade,
    //             }),
    //             headers:{
    //                 'Content-Type':'application/json'
    //             }
    //         })
    //         const res = await req.json()
    //         console.log(res)
    // }


    // const [lat, setLat] = useState(null);
    // const [lng, setLng] = useState(null);
    // const [status, setStatus] = useState(null);


    // const getLocation = async () => {
    //     if (!navigator.geolocation) {
    //     setStatus('Geolocation is not supported by your browser');
    //     } else {
    //     setStatus('Locating...');
    //     navigator.geolocation.getCurrentPosition((position) => {
    //         setStatus(null);
    //         postLocation(position.coords.latitude, position.coords.longitude)

    //         setLat(position.coords.latitude);
    //         setLng(position.coords.longitude);
    //     }, () => {
    //         setStatus('Unable to retrieve your location');
    //     });
    //     }
    // }

    useEffect(()=>{
        // getCidades()
    },[])
    
    const popupMessage = (item, e) => {

        if( cidade !== e.target.value && cidade !== "" ){


            console.log(e.target.value)
          /*
            Caso o nome da cidade for diferente do nome
            do item clicado a função é executada
          */
          let popup = document.getElementById(`${item.Cidade.nome}`)
          popup.classList.toggle("show")
    
          setTimeout(() => {
            popup.classList.remove('show')
          }, 3000)
    
        }
      }

        return (
        <div>

            {/* <button onClick={getLocation}>Get Location</button>
            <h1>Coordinates</h1>
            <p>{status}</p>
            {lat && <p>Latitude: {lat}</p>}
            {lng && <p>Longitude: {lng}</p>} */}



            <header className='header'>
                <div className='header-container'>
                    <img className='logo-vermelho' src={logo} alt="Logo appedidos"/>
                
                    <div onClick={() => openModal()} className='location-container'>
                        <img className='location-icon' src={locationIcon} alt="Ícone de Localização"/>


                        <span  className='location-text'>

                        {cidade == "" ?

                            "Selecione sua cidade"
                            :
                            `${cidade}/${siglaEstado}`
                        } 
                        
                        </span>

                        <img className='arrow-icon' src={arrowIcon} alt="Ícone flecha"/>
                    </div>
                    
                    <div className='porque-appedidos-container no-show' onClick={()=> document.getElementById('porque-appedidos').scrollIntoView()}>

                        <span className='porque-appedidos-text'>
                            Por que escolher a appedidos
                        </span>

                    </div>
                    
                    <div className='como-funciona-container no-show' onClick={()=> document.getElementById('como-funciona').scrollIntoView()}>

                        <span className='como-funciona-text'>
                            Como funciona
                        </span>

                    </div>

                    {/* <div className='o-que-falam-container no-show' onClick={()=> document.getElementById('o-que-falam').scrollIntoView()}>

                        <span className='o-que-falam-text'>
                            O que falam
                        </span>

                    </div> */}

                    <div className='duvidas-container no-show' onClick={()=> document.getElementById('duvidas').scrollIntoView()}>

                        <span className='duvidas-text'>
                            Dúvidas
                        </span>

                    </div>

                    <div>

                        <span className='cadastrar-agora-text btn-cadastrar no-show' onClick={()=> document.getElementById('a').scrollIntoView()}>
                            Cadastrar agora
                        </span>

                    </div>
                    
                </div> 
            </header>

        <div className="line-div"></div>

        <div id="modal-selecione-sua-cidade" className="modal-selecione-sua-cidade escondidoDireita">

                
            <div className="modal">

                <div className='modal-content'>
                    <div>
                        <AiFillCloseCircle
                        onClick={() => closeModal()}
                        className="closeBtn"
                        />
                    </div>
                    
                    <div className="modal-title">
                        Selecione sua cidade 
                        para descobrir planos <span> </span>
                        <span className="text-style-1"></span>
                        <span className="text-style-2"> exclusivos </span>
                        na sua região
                    </div>

                    <div className="estado-modal margin-sp">
                        São Paulo
                    </div>

                    <div className='cidade-modal-container-sp'>

                        {spCidadesArray.map((item) => (
                            <div class="popup" onClick={(e) => popupMessage(item, e)}>

                                <button
                                    value={item.Cidade.nome}
                                    className="cidade-border"
                                    onClick={(e) => {
                                        getCity(item, e);
                                    }}
                                >
                                    <span class="popuptext" id={item.Cidade.nome}>
                                        Desmarque a opção {cidade}
                                    </span>
                                    {item.Cidade.nome}
                                </button>
                            </div>
                        ))}
     
                    </div>

                    <div className='estado-modal'>
                        Mato Grosso do Sul
                    </div>

                    <div className='cidade-modal-container-ms'>

                        {msCidadesArray.map((item) => (
                        <div class="popup" onClick={(e) => popupMessage(item, e)}>

                        <button
                            value={item.Cidade.nome}
                            className="cidade-border"
                            onClick={(e) => {
                                getCity(item, e);
                            }}
                            >
                            <span class="popuptext" id={item.Cidade.nome}>
                                Desmarque a opção {cidade}
                            </span>
                            {item.Cidade.nome}  
                        </button>

                        </div>
                        ))}

                    </div> 
                    

                    <div className='alterar-location-btn' onClick={() => closeModal()}>
                        <span class="alterar-location-text">
                            Alterar localização
                        </span>
                    </div>

                    <div className='sugerir-cidade-conainer'>

                        <span className="sugerir-cidade-text" >
                            Não achou sua cidade na lista?<br/>
                            <a className="text-style-2" target="_blank" href="https://cadastro.appedidos.com.br/">
                                Clique aqui 
                            </a>
                            {" "} para sugeri-la
                        </span>

                    </div>

                </div>
            </div>
            
        </div>
        
    </div>
    )
}

export default Header