import React, { useState, useRef, useEffect } from "react";
import "./Faq.css"


/* Import de imagens e icons */
import { FiPlus, FiMinus } from "react-icons/fi";
import emojiMoneyBag from "../../img/faq/moneyBag.png"
import emojiMoto from "../../img/faq/motocycle.png"
import emojiEmpresa from "../../img/faq/empresa.png"
import emojiCelular from "../../img/cards/emojiCelular/celular.png"
import emojiDinheiro from "../../img/cards/emojiDinheiro/dinheiro.png"
import emojiOlho from "../../img/faq/emojiOlho.png"
import emojiCancelar from "../../img/faq/emojiCancelar.png"
import emojiPensativo from "../../img/faq/emojiPensativo.png"
import emojiCalendario from "../../img/faq/calendario.png"

function Faq(){
    const [active, setActive] = useState(false);
    const [active2, setActive2] = useState(false);
    const [active3, setActive3] = useState(false);
    const [active4, setActive4] = useState(false);
    const [active5, setActive5] = useState(false);
    const [active6, setActive6] = useState(false);
    const [active7, setActive7] = useState(false);
    const [active8, setActive8] = useState(false);

  
    const contentRef = useRef(null);
    const contentRef2 = useRef(null);
    const contentRef3 = useRef(null);
    const contentRef4 = useRef(null);
    const contentRef5 = useRef(null);
    const contentRef6 = useRef(null);
    const contentRef7 = useRef(null);
    const contentRef8 = useRef(null);
  
    useEffect(() => {
      contentRef.current.style.maxHeight = active
        ? `${contentRef.current.scrollHeight}px`
        : "0px";
    }, [contentRef, active]);

    useEffect(() => {
        contentRef2.current.style.maxHeight = active2
          ? `170px`
          : "0px";
      }, [contentRef2, active2]);

      useEffect(() => {
        contentRef3.current.style.maxHeight = active3
          ? `176px`
          : "0px";
      }, [contentRef3, active3]);

    //   useEffect(() => {
    //     contentRef4.current.style.maxHeight = active4
    //       ? `${contentRef4.current.scrollHeight}px`
    //       : "0px";
    //   }, [contentRef4, active4]);

    //   useEffect(() => {
    //     contentRef5.current.style.maxHeight = active5
    //       ? `${contentRef5.current.scrollHeight}px`
    //       : "0px";
    //   }, [contentRef5, active5]);

    //    useEffect(() => {
    //     contentRef6.current.style.maxHeight = active6
    //       ? `${contentRef6.current.scrollHeight}px`
    //       : "0px";
    //   }, [contentRef6, active6]);
      
    //   useEffect(() => {
    //     contentRef7.current.style.maxHeight = active7
    //       ? `${contentRef7.current.scrollHeight}px`
    //       : "0px";
    //   }, [contentRef7, active7]);

    //   useEffect(() => {
    //     contentRef8.current.style.maxHeight = active8
    //       ? `${contentRef8.current.scrollHeight}px`
    //       : "0px";
    //   }, [contentRef8, active8]);



    const toggleAccordion = () => {
      setActive(!active);
    };

    const toggleAccordion2 = () => {
        setActive2(!active2);
      };

    const toggleAccordion3 = () => {
        setActive3(!active3);
    };

    // const toggleAccordion4 = () => {
    //     setActive4(!active4);
    // };

    // const toggleAccordion5 = () => {
    //     setActive5(!active5);
    // };

    // const toggleAccordion6 = () => {
    //     setActive6(!active6);
    // };

    // const toggleAccordion7 = () => {
    //     setActive7(!active7);
    // };

    // const toggleAccordion8 = () => {
    //     setActive8(!active8);
    // };


    return(
        <>
            <div className="gray-line-faq"></div>

            <div className='main-container-faq'>

                <div id="duvidas" className="duvidas-frequentes-title">
                    Ficou com <br></br>alguma dúvida?
                </div>

                <div className="duvidas-frequentes-text">
                    Separamos as dúvidas mais frequentes
                </div>

            </div>

            
            {/* Pergunta 1 */}

            <div className="faq-container" onClick={toggleAccordion}> 

                <div className="faq-box"> 

                    <img className="faq-emoji" src={emojiMoneyBag} alt="Emoji Saco de Dinheiro"/>
                
                    <div className="main-container-faq">

                        <button className={`question-section ${active}`} >
                            
                            <div className='faq-pergunta'>

                                <div id="faq-question1" className="faq-question-mobile1">
                                    Como o cliente paga pelo pedido?
                                </div>

                                {active ? 
                                <FiMinus
                                    className={`question-icon `}
                                />
                                    :
                                <FiPlus
                                    className={`question-icon`} 
                                />}
                                
                            </div>

                            <div
                                ref={contentRef}
                                className={active ? `answer ` : `answer`}
                                >

                                <p  className="faq-answer faq-answer-mobile1">
                                    O pedido pode ser pago direto para o restaurante (cartão, pix, etc) ou pelo próprio aplicativo (pagamento online)
                                </p>

                            </div>
                        
                        </button>
                    </div>
                </div>
            </div>

            {/* Pergunta 2 */}

            <div className="faq-container" onClick={toggleAccordion2}>

                <div className="faq-box"> 

                    <img className="faq-emoji" src={emojiCalendario} alt="Emoji de um calendário"/>
                
                    <div className="main-container-faq">

                        <button className={`question-section `}>
                            
                            <div className='faq-pergunta'>

                                <div id="faq-question2" className="faq-question-mobile2">
                                    Quando vou receber os repasses do pagamento online?
                                </div>

                                {active2 ? 
                                <FiMinus
                                    className={`question-icon `}
                                />
                                    :
                                <FiPlus
                                    className={`question-icon`} 
                                />}
                                
                            </div>

                            <div
                                ref={contentRef2}
                                className={active2 ? `answer ` : `answer`}
                                >

                                <p className="faq-answer faq-answer-mobile2">
                                    O repasse será enviado todo dia 01 e 16 de todo mês, direto na conta do restaurante.
                                </p>

                            </div>
                        
                        </button>
                    </div>
                </div>
            </div>

            {/* Pergunta 3 */}

            <div className="faq-container" onClick={toggleAccordion3}>

                <div className="faq-box">

                    <img className="faq-emoji" src={emojiOlho} alt="Emoji Olho"/>
                
                    <div className="main-container-faq">

                        <button className={`question-section `}>
                            
                            <div className='faq-pergunta'> 

                                <div id="faq-question3" className="faq-question-mobile3">
                                    O que preciso pra começar?
                                </div>

                                {active3 ? 
                                <FiMinus
                                    className={`question-icon `}
                                />
                                    :
                                <FiPlus
                                    className={`question-icon`} 
                                />}
                                
                            </div>

                            <div
                                ref={contentRef3}
                                className={active3 ? `answer ` : `answer`}
                                >

                                <p className="faq-answer faq-answer-mobile3">
                                    É necessário ter um celular com boa conexão com a internet e também um CNPJ ativo!
                                </p>

                            </div>
                        
                        </button>
                    </div>
                </div>
            </div>

            {/* Pergunta 4 */}

            {/* <div className="faq-container" onClick={toggleAccordion4}>

                <div className="faq-box">

                    <img className="faq-emoji" src={emojiMoto} alt="Emoji Motocicleta"/>
                
                    <div className="main-container-faq">

                        <button className={`question-section `}>
                            
                            <div className='faq-pergunta'>

                                <div id="faq-question4" className="faq-question-mobile4">
                                    Preciso ter entregador?
                                </div>

                                {active4 ? 
                                <FiMinus
                                    className={`question-icon `}
                                />
                                    :
                                <FiPlus
                                    className={`question-icon`} 
                                />}
                                
                            </div>

                            <div
                                ref={contentRef4}
                                className={active4 ? `answer ` : `answer`}
                                >

                                <p className="faq-answer faq-answer-mobile4">
                                    Sim, é necessário que o restaurante já tenha o serviço de entrega.
                                </p>

                            </div>
                        
                        </button>
                    </div>
                </div>
            </div> */}

            {/* Pergunta 5 */}

            {/* <div className="faq-container" onClick={toggleAccordion5}>

                <div className="faq-box">

                    <img className="faq-emoji" src={emojiEmpresa} alt="Emoji Prédio Empresarial"/>
                
                    <div className="main-container-faq">

                        <button className={`question-section `}>
                            
                            <div className='faq-pergunta'>

                                <div id="faq-question5" className="faq-question-mobile5">
                                    Preciso ter CNPJ pra cadastrar meu restaurante?
                                </div>

                                {active5 ? 
                                <FiMinus
                                    className={`question-icon `}
                                />
                                    :
                                <FiPlus
                                    className={`question-icon`} 
                                />}
                                
                            </div>

                            <div
                                ref={contentRef5}
                                className={active5 ? `answer ` : `answer`}
                                >

                                <p className="faq-answer faq-answer-mobile5">
                                    Sim! É necessário ter um CNPJ (pode ser Mei) pra cadastrar seu restaurante <br/>
                                    na appedidos. Ainda não tem um CNPJ? <span></span>
                                    <span class="text-style-1">
                                        <a target="_blank" href="https://www.gov.br/empresas-e-negocios/pt-br/empreendedor">
                                            Saiba como abrir
                                        </a>
                                    </span>
                                </p>

                            </div>
                        
                        </button>
                    </div>
                </div>
            </div> */}

            {/* Pergunta 6 */}

            {/* <div className="faq-container" onClick={toggleAccordion6}>

                <div className="faq-box">

                    <img className="faq-emoji" src={emojiOlho} alt="Emoji Olho"/>
                
                    <div className="main-container-faq">

                        <button className={`question-section `}>
                            
                            <div className='faq-pergunta'>

                                <div id="faq-question6" className="faq-question-mobile6">
                                    Como recebo meus pedidos?
                                </div>

                                {active6 ? 
                                <FiMinus
                                    className={`question-icon `}
                                />
                                    :
                                <FiPlus
                                    className={`question-icon`} 
                                />}
                                
                            </div>

                            <div
                                ref={contentRef6}
                                className={active6 ? `answer ` : `answer`}
                                >

                                <p className="faq-answer faq-answer-mobile6">
                                    Você irá receber os pedidos no seu celular e também no seu computador, <br></br>através do Gestor de Negócios. 
                                    É importante ter um dispositivo exclusivo da loja.
                                </p>

                            </div>
                        
                        </button>
                    </div>
                </div>
            </div> */}

            {/* Pergunta 7 */}

            {/* <div className="faq-container" onClick={toggleAccordion7}>

                <div className="faq-box">

                    <img className="faq-emoji" src={emojiCancelar} alt="Emoji Cancelar"/>
                
                    <div className="main-container-faq">

                        <button className={`question-section `}>
                            
                            <div className='faq-pergunta'>

                                <div id="faq-question7" className="faq-question-mobile7">
                                    Regra de cancelamento
                                </div>

                                {active7 ? 
                                <FiMinus
                                    className={`question-icon `}
                                />
                                    :
                                <FiPlus
                                    className={`question-icon`} 
                                />}
                                
                            </div>

                            <div
                                ref={contentRef7}
                                className={active7 ? `answer ` : `answer`}
                                >

                                <p className="faq-answer faq-answer-mobile7">
                                    Você deverá se comprometer a atender e entregar pelo menos 90% dos <br></br>pedidos realizados pelos clientes.
                                </p>

                            </div>
                        
                        </button>
                    </div>
                </div>
            </div> */}

            {/* Pergunta 8 */}

            {/* <div className="faq-container" onClick={toggleAccordion8}>

                <div className="faq-box">

                    <img className="faq-emoji" src={emojiPensativo} alt="Emoji Pensativo"/>
                
                    <div className="main-container-faq">

                        <button className={`question-section `}>
                            
                            <div className='faq-pergunta'>

                                <div id="faq-question8" className="faq-question-mobile8">
                                    Regra de recebimento dos pedidos
                                </div>

                                {active8 ? 
                                <FiMinus
                                    className={`question-icon `}
                                />
                                    :
                                <FiPlus
                                    className={`question-icon`} 
                                />}
                                
                            </div>

                            <div
                                ref={contentRef8}
                                className={active8 ? `answer ` : `answer`}
                                >

                                <p className="faq-answer faq-answer-mobile8">
                                    Os pedidos deverão ser recebidos em até 8 minutos, caso contrário a plataforma <br></br>cancela o pedido e notifica o cliente. 
                                    Restaurantes com ocorrência de pedidos <br></br>cancelados (pela plataforma) perdem visibilidade dentro do aplicativo e podem<br></br> ser desativados.                                </p>

                            </div>
                        
                        </button>
                    </div>
                </div>
            </div> */}







        </>
    )
}

export default Faq;