import React, { useState, useEffect } from 'react';
import './Home.css';
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer';
import ComoFunciona from './ComoFunciona/ComoFunciona';
import CarouselParceiros from './CarouselParceiros/CarouselParceiros';
import Faq from './Faq/Faq'
import EtapasCadastro from './EtapasCadastro/EtapasCadastro'
import CidadeEscolhida from './CidadeEscolhida/CidadeEscolhida'

/*Import de imagens e icons */
import aumenteVendasImg from "../img/aumente-vendas.png"
import emojiContrato from "../img/cards/emojiContrato/contrato.png";
import emojiCelular from "../img/cards/emojiCelular/celular.png"
import emojiDinheiro from "../img/cards/emojiDinheiro/dinheiro.png"
import emojiApaixonado from "../img/cards/emojiApaixonado/apaixonado.png"
import emojiComputador from "../img/cards/emojiComputador/computador.png"
import emojiCorda from "../img/cards/emojiCorda/corda.png"
import emojiCadeado from "../img/cards/emojiCadeado/cadeado.png"
import emojiEscrevendo from "../img/cards/emojiEscrevendo/escrevendo.png"
import fatureMais from "../img/FatureMais/fatureMais.png"
import emojiPensando from "../img/emojiPensando.png"
import aumenteVendasImgMobile from "../img/aumente-vendas-mobile.png"
import bandeiraBR from "../img/bandeira-br.png"
import whatsappIcon from '../img/Icons/whatsapp-icon.png';
import whatsappIcone from '../img/Icons/whatsapp-icone.png';
import {AiFillCloseCircle,} from "react-icons/ai";
import seta from '../img/Icons/seta.png';
import emojiOlho from "../img/cards/emoji-olho.png";
import emojiApertoMao from "../img/cards/emoji-aperto-mao.png";


import api from '../services/api'
import { css, positions } from '@mui/system';


function Home(){

    const [cidadeData, setCidadeData] = useState({});

    const [cidade, setCidade] = useState("");


    const [siglaEstado, setSiglaEstado] = useState("");

    const [titulo, setTitulo]= useState("Aumente as vendas do seu delivery");
    const [descricao, setDescricao]= useState("Saia na frente da concorrência e atraia novos clientes pro seu restaurante");
    const [urlVideo1, setUrlVideo1] = useState ("https://www.youtube.com/embed/vuHG_hyPY_A");
    const [tituloVideo1, setituloVideo1] = useState("Fernando Gonzales")
    const [descricaoVideo1, setDescricaoVideo1] = useState("Subway Araçatuba")
    const [urlVideo2, setUrlVideo2] = useState ("https://www.youtube.com/embed/vuHG_hyPY_A");
    const [tituloVideo2, setituloVideo2] = useState("Roberto Yamada")
    const [descricaoVideo2, setDescricaoVideo2] = useState("Benedito Gastronomia")
    const [urlVideo3, setUrlVideo3] = useState ("https://www.youtube.com/embed/vuHG_hyPY_A");
    const [tituloVideo3, setituloVideo3] = useState("Cibele Moura")
    const [descricaoVideo3, setDescricaoVideo3] = useState("Cacau Show Araçatuba")
    const [porcentagemVendas, setPorcentagemVendas] = useState ("10")
    const [carouselPrincipal, setCarouselPrincipal] = useState([])
    const [carouselCidade, setCarouselCidade] = useState([])
    const [isModalVisible, setIsModalVisible] = useState(false)

    const getCity = (item, e) => {

        const cidadeEscolhida = document.getElementsByClassName("citySelected");

        if (cidadeEscolhida.length < 1) {
              e.target.classList.add("citySelected");
        
            if (cidadeEscolhida.length === 1){
                setCidade(e.target.value)
                cityData(item.Cidade.id)
                setSiglaEstado(item.Estado.sigla)
            }

        } else if (e.target.classList.contains("citySelected")){
            e.target.classList.remove("citySelected");
            setCidade("")
            setSiglaEstado("") 
            setTitulo("Aumente as vendas do seu delivery")
            setDescricao("Saia na frente da concorrência e atraia novos clientes pro seu restaurante")
            setPorcentagemVendas("10")
        }

    };
    
    async function cityData(cidade_id){
  
      const req = await fetch(`${api}`, {
              method:'POST',
              body: JSON.stringify({
                  cidade_id: cidade_id,
              }),
              headers:{
                  'Content-Type':'application/json'
              }
          })
          const res = await req.json()
          console.log(res)
          if(res.data == true){

            console.log(res.city)
            setTitulo(res.city.titulo)
            setDescricao(res.city.descricao)
            setUrlVideo1(res.city.url_video1)
            setituloVideo1(res.city.titulo_video1)
            setDescricaoVideo1(res.city.descricao_video1)
            setUrlVideo2(res.city.url_video2)
            setituloVideo2(res.city.titulo_video2)
            setDescricaoVideo2(res.city.descricao_video2)
            setUrlVideo3(res.city.url_video3)
            setituloVideo3(res.city.titulo_video3)
            setDescricaoVideo3(res.city.descricao_video3)
            setPorcentagemVendas(res.city.porcentagem_vendas)
            setCarouselPrincipal(res.city.carousel_principal)
            setCarouselCidade(res.city.carousel_cidade)
          } else {
              console.log(res.data)
          }
    }

    useEffect(() => {
    setCidadeData({
        cidade,
        siglaEstado,
        titulo,
        descricao,
        urlVideo1,
        tituloVideo1,
        descricaoVideo1,
        urlVideo2,
        tituloVideo2,
        descricaoVideo2,
        urlVideo3,
        tituloVideo3,
        descricaoVideo3,
        porcentagemVendas,
        carouselPrincipal,

        });
    }, [
        cidade,
        siglaEstado,
        titulo,
        descricao,
        urlVideo1,
        tituloVideo1,
        descricaoVideo1,
        urlVideo2,
        tituloVideo2,
        descricaoVideo2,
        urlVideo3,
        tituloVideo3,
        descricaoVideo3,
        porcentagemVendas,
        carouselPrincipal,
    ]);



    function WhatsAppButton() {
        return (
            <div className='div-whats'>
                <a href='https://appedidos.app/falar-time-comercial-vantagens?text=Oi!%20Estava%20navegando%20pelo%20vantagens%20mas%20preciso%20de%20ajuda%20para%20cadastrar%20meu%20restaurante!' target="_blank">
                    <img className="whats" src={whatsappIcone} alt="icone whatsapp"/>
                </a>
            </div>
        );
    }

    const openModalAdamantina = () => {
        const tela = document.getElementById("modal-promotion-adamantina")
        tela.classList.remove("escondidoDireita");
    };

    const closeModalAdamantina = () => {
        const tela = document.getElementById("modal-promotion-adamantina");
        tela.classList.add("escondidoDireita");
    };

    const openModalGuararapes = () => {
        const tela = document.getElementById("modal-promotion-guararapes")
        tela.classList.remove("escondidoDireita");
    };

    const closeModalGuararapes = () => {
        const tela = document.getElementById("modal-promotion-guararapes");
        tela.classList.add("escondidoDireita");
    };
    

    return(
    <div>
        <Header getCity={getCity} cidade={cidade} siglaEstado={siglaEstado} />

        {/*DESKTOP background rosa hero */}
        <div className='graphic-div no-show-mobile '>
            
            <span className='pink-bg no-show-mobile'></span>

        </div>


        {/*DESKTOP imagem grafico hero */}
        <div className='container-img-grafico no-show-mobile'>

            <div className='graphic-div2 no-show-mobile'>
                <img className='imagem-hero no-show-mobile' src={aumenteVendasImg} alt="Imagem Gráfico de Vendas Subindo"/>
            </div>
        </div>

        <WhatsAppButton />

        <div className='main-container-aumente-vendas'>

            <div className='aumente-as-vendas-text'>
                {titulo}
            </div>

            {   cidade == "" ||   
                cidade == "Guararapes" || 
                cidade == "Penápolis" ||
                cidade == "Adamantina" ? 
                
                <div className='venda-mais-text '>
                {descricao}
                </div>

                :

                <CidadeEscolhida 
                cidade={cidade} 
                siglaEstado={siglaEstado} 
                carouselCidade={carouselCidade}
                />
            }

            <div className='quero-conhecer-btn quero-conhecer-text' onClick={()=> document.getElementById('porque-appedidos').scrollIntoView()}>
                Quero conhecer
            </div>

        </div>

        {/*MOBILE background rosa hero */}
        <div className='graphic-div no-show-desktop'>
                
            <span className='pink-bg no-show-desktop'></span>

        </div>


        {/* MOBILE, imagem grafico hero */}
        <div className='graphic-div2-mobile no-show-desktop'>

            <img className='imagem-hero-mobile no-show-desktop ' src={aumenteVendasImgMobile} alt="Imagem Gráfico de Vendas Subindo"/>

        </div>

        <div className='porque-appedidos-container2'>

            <div id="porque-appedidos" className='porque-appedidos-text2'>
                Porque escolher <br></br>
                a appedidos?
            </div>

            <div className='somos-uma-plataforma'>
                Sabemos que ter um negócio não é fácil,
                por isso você pode contar com a gente
            </div>

            {/*   --Aqui começa os Cards--   */}
        
            <div className='row1'>
                <div className='card-row-1 card-contrato'>
                    <img className='emoji-cards' src={emojiOlho} alt="Emoji de dois olhos"/>

                    <div className='contrato-title'>
                        Seja visto
                    </div>

                    <div className='contrato-text'>
                      Faça parte de uma plataforma com milhares de acessos diários e atraia novos clientes
                    </div>
                </div>

                <div className='card-row-1 card-app'>
                    <img className="emoji-cards" src={emojiDinheiro} alt="Emoji de Dinheiro"/>

                    <div className='pagamento-app-title'>
                        Aumente o seu faturamento
                    </div>

                    <div className='pagamento-app-text'>
                        Mais um canal de vendas para o seu negócio, venda para novos clientes e fature muito mais
                    </div>
                </div>

                <div className='card-row-1 card-custo-beneficio'>
                    <img className='emoji-cards' src={emojiApertoMao} alt="Emoji de aperto de mão"/>

                    <div className='custo-beneficio-title'>
                        Bom para todos
                    </div>

                    <div className='custo-beneficio-text'>
                        Sem taxas ocultas ou pegadinhas, aqui você paga, um valor justo, apenas sobre as vendas
                    </div>
                </div>
            </div>

            <div className='row2'>
                <div className='card-row-2 nao-precisa-computador-card'>

                    <img className='emoji-cards' src={emojiCelular} alt="Emoji de um celular"/>

                    <div className='nao-precisa-computador-title'>
                        Simples, como tem que ser
                    </div>

                    <div className='nao-precisa-computador-text'>
                        Para começar a vender e lucrar na plataforma, basta ter um celular com internet, simples assim!
                    </div>

                </div>
            </div>
        </div>

        {/* Como funciona a Plataforma? */}

        <ComoFunciona />


        {/* Aqui começam os depoimentos */}

        {/* <div className='red-background-container'>
            <div className='main-container-red'>

                <div id='o-que-falam' className='red-background-title'>
                    O que falam <br></br>da appedidos?

                    <div className="red-background-text">
                        Depoimentos dos nossos parceiros
                    </div>

                </div>
            </div>
        </div>

       

        <div className='depoimentos-container'>

            <div>
                <iframe className="depoimento-video" src={urlVideo1}/>
                <div className="depoimento-title">{tituloVideo1}</div>
                <div className="depoimento-cidade">{descricaoVideo1}</div>
            </div>

            <div>
                <iframe className="depoimento-video" src={urlVideo2}/>
                <div className="depoimento-title">{tituloVideo2}</div>
                <div className="depoimento-cidade">{descricaoVideo2}</div>
            </div>

            <div>
                <iframe className="depoimento-video" src={urlVideo3}/>
                <div className="depoimento-title">{tituloVideo3}</div>
                <div className="depoimento-cidade">{descricaoVideo3}</div>
            </div>

        </div> */}
        
        {/*qnd descomentar a parte de "depoimentos" apagar a div com a classe "espaco-mobile-gray-line" no arquivo "ComoFunciona"   */}
        <div className="gray-line"></div>

        {/* Aqui começa sessão parceiros */}

        <div className='main-container'>

            <div className='parceiros-container'>

                <div className='parceiros-title'>
                    Parceiros
                </div>
            
                <div class="parceiros-text">
                    <span class="text-style-1">Alguns parceiros que </span>
                    confiam<br></br>

                    <span class="text-style-1">
                        e <span> </span>
                    </span>
                    crescem com a gente
                </div>

                <div className='carousel-marcas'>

                    <CarouselParceiros carouselPrincipal={carouselPrincipal}/>
                    
                </div>

            </div>
        </div>

        {/* Dúvidas frenquentes */}

        <Faq/>


        {/*Etapas do Cadastro*/}

        <EtapasCadastro/>

        <div className='red-background-container2 '>

            <div className='main-container'>

                <div id='a' className="vem-pra-appedidos-title">
                    Vem pra appedidos
                </div>
                
                <div className="vem-pra-appedidos-subtitle">
                    
                <span>
                    Fature mais
                </span>

                <span>
                    {cidade ? ` em ` : ""}
                </span>

                <span className=" text-style-1">
                    {cidade ? `${cidade} / ${siglaEstado}` : ""}
                </span>
                    <span> vendendo com a gente 💰</span>

                </div>

                {/* {cidade == "Adamantina" ?

                    <div className="white-box-rectangle">

                        <div className='white-box-content'>

                            <div className='row'>
                                <div className='coluna'>
                                    <span className='text-de'>de</span>

                                    <div className='div-porcentagem'>
                                        <span className="valor-porcentagem-full">{porcentagemVendas}</span>
                                        <span className="simbolo-porcentagem-promissao">%</span>
                                    </div>
                                </div>

                                <img className="seta" src={seta} alt=""/>

                                <div className='coluna'>
                                    <span className='text-por'>por</span>
                                    
                                    <div className='div-porcentagem'>
                                        <span className="valor-porcentagem-desconto">8</span>
                                        <span className="simbolo-porcentagem-promissao1">%</span>

                                        <div className='periodo'>
                                            <span className='periodo-text'> Durante 2 meses* </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <span class="div-text-porcentagem text-porcentagem">
                                Sobre as vendas <br></br>no aplicativo
                            </span>

                            <div class="porcentagem-pagamento-online">
                                <span class="text-style-1">
                                + 3,99%</span> por pagamento online
                            </div>

                            <div className="mensalidade">
                                Sem mensalidade
                            </div>

                            <div className="delivery-text">
                                Entrega feita pelo restaurante
                            </div>
                
                            <a className="btn-cadastro-rest" target="_blank" href="https://cadastro.appedidos.com.br/">
                                <span className="cadastrar-rest">
                                    Cadastrar meu restaurante 
                                </span>
                            </a>

                            <div className="btn-promotion" onClick={() => openModalAdamantina()}>
                                <span className="cadastrar-rest2">
                                    Entenda a promoção
                                </span>
                            </div>
                        </div>
                    </div>

                    : */}

                    <div className="white-box-rectangle">

                        <div className='white-box-content'>

                            <div className='div-porcentagem'>

                                <span className="valor-porcentagem">{porcentagemVendas}</span>
                                <span className="simbolo-porcentagem">%</span>
                                
                            </div>

                            <span class="div-text-porcentagem text-porcentagem">
                                Sobre as vendas <br></br>no aplicativo
                            </span>

                            <div class="porcentagem-pagamento-online">
                                <span class="text-style-1">
                                + 3,99%</span> por pagamento online
                                <br/>
                                <br/>
                                <span class="text-style-1">
                                50% de desconto</span> na taxa de cadastro
                            </div>

                            <div className="mensalidade">
                                Sem mensalidade
                            </div>

                            <div className="delivery-text">
                                Entrega feita pelo restaurante
                            </div>

                            <a className="btn-cadastro-rest" target="_blank" href="https://cadastro.appedidos.com.br/">
                                <span className="cadastrar-rest">
                                    Cadastrar meu restaurante
                                </span>
                            </a>

                        </div>
                    </div>
                {/* } */}

            </div>
        </div>

        {/* MODAL PROMOÇÃO */}

        {/* MODAL ADAMANTINA */}
        {/* <div id="modal-promotion-adamantina" className="modal-promocao escondidoDireita">

            <div className="modal-promo">

                <div className='modal-content-promo'>
                    <div className='modal-text-mobile'>
                        <div>
                            <AiFillCloseCircle
                            onClick={() => closeModalAdamantina()}
                            className="closeBtn"
                            />
                        </div>

                        <div className='modal-title-promo'>
                            Entenda como funciona
                        </div>
                        
                        <div className='modal-subtitle-promo'>
                            De 10% por 8% durante 2 meses
                        </div>    

                        <div className='modal-text-promo'>
                            <p className='p-margin'><span className='modal-text1-promo'> • </span>
                                A taxa será diminuída do valor de 10% para 8% 
                                durante os 2 primeiros meses de uso na plataforma.
                            </p>
                            <p className='p-margin'><span className='modal-text1-promo'> • </span>
                                Oferta válida somente para novos restaurantes.
                            </p>
                            <p className='p-margin'><span className='modal-text1-promo'> • </span>
                                Início no dia 10/04/2023 com prazo inderteminado de validade.
                            </p>
                        </div>

                        <div className="btn-entendi" onClick={() => closeModalAdamantina()}>
                            <span className="entendi">
                                Ok, entendi
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        
        <div className='main-container'> 

            <div className="fature-mais no-show-mobile">
                <img className="img-fature-mais" src={fatureMais} alt="Ilustração Mulher no Computador"/>
            </div>

            <div className="acompanhar-processo-container">

                <div>
                    <img className="emojiPensando" src={emojiPensando} alt="Emoji Pensando"/>
                </div>

                <span class="acompanhar-processo-text">
                    Já fez o cadastro <br></br>
                    do seu restaurante?
                </span>

                <a className="btn-acompanhar-processo" target="_blank" href="https://vantagens.appedidos.com.br/a/">
                    <span className="btn-acompanhar-processo-text">Acompanhar processo</span>
                </a>

            </div>
        </div>

        <Footer />

    </div>
    
    )
}

export default Home;