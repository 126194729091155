import React from "react";
import "./CarouselParceiros.css"

import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import { useKeenSlider } from 'keen-slider/react'

import acai from "../../img/logoRestaurantes/acai-active.png"
import gordinho from "../../img/logoRestaurantes/gordinho-active.png"
import lepingue from "../../img/logoRestaurantes/lepingue-active.png"
import donaAmelia from "../../img/logoRestaurantes/dona-amelia-active.png"
import giraffas from "../../img/logoRestaurantes/giraffas-active.png"
import cacauShow from "../../img/logoRestaurantes/cacaushow-active.png"
import ticos from "../../img/logoRestaurantes/ticos-active.png"
import subway from "../../img/logoRestaurantes/subway-active.png"
import oldbeer from "../../img/logoRestaurantes/oldbeer-active.png"

// slider.animator.start([keyframe1, keyframe2])


function CarouselParceiros({carouselPrincipal}){
  
    const quantidade = carouselPrincipal
    const perView = (quantidade.length==0 ? 7.5 : parseFloat(quantidade.length) - 1.5 )  
    const perViewMobile = (quantidade.length==0 ? 2.5 : parseFloat(quantidade.length) - 6.5 ) 
    // console.log(perView)
    // console.log(perViewMobile)

    const [sliderRef] = useKeenSlider(
        {
          loop: true,
          mode: "free-snap",
          slides: {
            perView: parseFloat(perView),
            spacing: 0,
          },
          breakpoints: {
            'only screen and (min-width: 360px) and (max-width: 1192px)': {
                loop: true,
                mode: "free-snap",
                slides: {
                  perView: parseFloat(perViewMobile),
                  spacing: 0,
                },
            },
          },
        },
        [
          (slider) => {
            let timeout
            let mouseOver = false
            function clearNextTimeout() {
              clearTimeout(timeout)
            }
            function nextTimeout() {
              clearTimeout(timeout)
              if (mouseOver) return
              timeout = setTimeout(() => {
                slider.next()
              }, 1670)
            }
            slider.on("created", () => {
              slider.container.addEventListener("mouseover", () => {
                mouseOver = true
                clearNextTimeout()
              })
              slider.container.addEventListener("mouseover", () => {
                mouseOver = false
                nextTimeout()
              })
              slider.container.addEventListener("mouseout", () => {
                mouseOver = false
                nextTimeout()
              })
              nextTimeout()
            })
            slider.on("dragStarted", clearNextTimeout)
            slider.on("animationStarted", nextTimeout)
            slider.on("animationEnded", nextTimeout)
            slider.on("updated", nextTimeout)
          },
        ]
      )
    return(
      <>
        {quantidade.length > 0 ?
          <div ref={sliderRef} className="keen-slider ">
            
            {quantidade.map((item, index) => 
              <div className="keen-slider__slide"> <img className="img-marcas" src={item} key={index} alt="Logo Parceiros"/></div>
            )}

          
          </div>
          :

          <div ref={sliderRef} className="keen-slider ">
            <div className="keen-slider__slide"> <img className="img-marcas" src={acai} alt="Logo Parceiros"/></div>
            <div className="keen-slider__slide"> <img className="img-marcas" src={gordinho} alt="Logo Parceiros"/></div>
            <div className="keen-slider__slide"> <img className="img-marcas" src={lepingue} alt="Logo Parceiros"/></div>
            <div className="keen-slider__slide"> <img className="img-marcas" src={donaAmelia} alt="Logo Parceiros"/></div>
            <div className="keen-slider__slide"> <img className="img-marcas" src={giraffas} alt="Logo Parceiros"/></div>
            <div className="keen-slider__slide"> <img className="img-marcas" src={cacauShow} alt="Logo Parceiros"/></div>
            <div className="keen-slider__slide"> <img className="img-marcas" src={ticos} alt="Logo Parceiros"/></div>
            <div className="keen-slider__slide"> <img className="img-marcas" src={subway} alt="Logo Parceiros"/></div>
            <div className="keen-slider__slide"> <img className="img-marcas" src={oldbeer} alt="Logo Parceiros"/></div> 

          
          </div>

        }
      </>
    )
}

export default CarouselParceiros;