/* São Paulo */
export const spCidadesArray = [
    {
        "Cidade":{
            "id":42,
            "nome":"Adamantina",
            "ativo":true,
            "estado_id":3
        },
        "Estado":{
            "sigla":"SP",
            "id":3,
            "nome":"São Paulo"
        }
    },
    {
        "Cidade":{
            "id":8,
            "nome":"Araçatuba",
            "ativo":true,
            "estado_id":3
        },
        "Estado":{
            "sigla":"SP",
            "id":3,
            "nome":"São Paulo"
        }
    },
    {
        "Cidade":{
            "id":17,
            "nome":"Guararapes",
            "ativo":true,
            "estado_id":3
        },
        "Estado":{
            "sigla":"SP",
            "id":3,
            "nome":"São Paulo"
        }
    },
    {
        "Cidade":{
            "id":16,
            "nome":"Penápolis",
            "ativo":true,
            "estado_id":3
        },
        "Estado":{
            "sigla":"SP",
            "id":3,
            "nome":"São Paulo"
        }
    },
    {
        "Cidade":{
            "id":34,
            "nome":"Promissão",
            "ativo":true,
            "estado_id":3
        },
        "Estado":{
            "sigla":"SP",
            "id":3,
            "nome":"São Paulo"
        }
    }
    // {
    //     id: 1,
    //     name: "Andradina",
    //   },
    //   {
    //     id: 2,
    //     name: "Araçatuba",
    //   },
    //   {
    //     id: 3,
    //     name: "Birigui",
    //   },
    //   {
    //     id: 4,
    //     name: "Guararapes",
    //   },
    //   {
    //     id: 5,
    //     name: "Ilha Solteira",
    //   },
    //   {
    //     id: 6,
    //     name: "Jales",
    //   },
    //   {
    //     id: 7,
    //     name: "Penápolis",
    //   },
    //   {
    //     id: 8,
    //     name: "Promissão",
    //   },
]

/* Mato Grosso do Sul */

export const msCidadesArray = [
    {
        "Cidade":{
            "id":22,
            "nome":"Três Lagoas",
            "ativo":true,
            "estado_id":6
        },
        "Estado":{
            "sigla":"MS",
            "id":6,
            "nome":"Mato Grosso do Sul"
        }
    }
    // {
    //   id: 9,
    //   name: "Paranaíba",
    // },
    // {
    //   id: 10,
    //   name: "Três Lagoas",
    // },
];



